import { Gracias } from './Gracias'
import React, { useEffect } from 'react'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { Route, Link, useParams, useHistory } from 'react-router-dom'

import FormPulso from './FormPulso'
import { Alert } from 'react-bootstrap'

export default function VistaPulso({ user, saveOnPlatform, userLogout, pulso, setPulso, loadPulseFromPlatform, updatePulseInPlatform }) {
	const { id } = useParams()
	// const history = useHistory()

	useEffect(() => {
		if (user === false) {
			loadPulseFromPlatform(id)
		}
	}, [id])

	return (
		<>
			{pulso && user ? (
				<>
					<Row>
						<Col md={{ span: 8, offset: 2 }} className="va-right form-atencion-container">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/">Inicio</Link>
									</li>
									<li className="breadcrumb-item">
										<Link to={'/responder/' + pulso._id.$oid}>Responder pulso de {pulso.monthNatural}</Link>
									</li>
								</ol>
							</nav>
							<Route path={['/responder/:id']} exact>
								<Row>
									<Col md={12} className="va-content">
										<FormPulso user={user} pulso={pulso} setPulso={setPulso} updatePulseInPlatform={updatePulseInPlatform} />
									</Col>
								</Row>
							</Route>
						</Col>
					</Row>
					<Row className="pt-5 pb-5">
						<Col>
							<hr />
							<Link to="/">
								<Button variant="info">
									<FaRegQuestionCircle style={{ position: 'relative', bottom: '2px', marginRight: '5px' }} />
									Regresar al Inicio
								</Button>
							</Link>
						</Col>
					</Row>
				</>
			) : (
				<div className="text-center">
					<Alert variant="warning">Espera un momento, estamos buscando el pulso a responder...</Alert>
				</div>
			)}
		</>
	)
}
