import { Col, Row, Alert } from 'react-bootstrap'
import { BotonAgregar } from './BotonAgregar'
import { BotonFinalizar } from './BotonFinalizar'
import { BotonGuardar } from './BotonGuardar'
import { BotonResponder } from './BotonResponder'

export default function BelowListOptions({ user, pulso }) {
	return (
		<Row>
			<Col md={12} className="text-center">
				{pulso && pulso._id ? (
					<>
						{pulso.completed == false ? (
							<>
								<p className="text-left">
									Para comenzar, haz clic en el siguiente link. Puedes contestarlo desde cualquier dispositivo. Te tomará menos de
									dos minutos.
								</p>
								<p className="text-left">Muchas gracias.</p>
								<BotonResponder pulso={pulso} />
							</>
						) : (
							<>
								<Alert variant="success">Gracias {user.userName}, ya has contestado el pulso de {pulso.monthNatural}</Alert>
							</>
						)}
					</>
				) : (
					<>
						<Alert variant="warning">Lo sentimos, aún no puedes responder el pulso de este mes. Regresa pronto</Alert>
					</>
				)}
			</Col>
		</Row>
	)
}
