import cogoToast from 'cogo-toast'
import React, { useState } from 'react'
import { Form, Badge, Button } from 'react-bootstrap'
import { FaSignInAlt } from 'react-icons/fa'

export function FormLogin({ platformURL, userLogin, setAlert, setLoadingBar }) {
	const env = process.env.REACT_APP_LOCAL_ENV || false

	const defaultLoginUser = () => {
		if (env !== 'production' && env !== 'test') {
			return 'areli@lavaca.edu.mx'
		}
		return ''
	}
	const [userLoginEmail, setuserLoginEmail] = useState(defaultLoginUser)
	const [userLoginPassword, setuserLoginPassword] = useState('')

	const userLoginHandle = (e) => {
		e.preventDefault()
		if (!userLoginEmail) {
			cogoToast.warn('Lo sentimos, debes especificar tu correo electrónico')
			return false
		}
		if (!userLoginPassword) {
			cogoToast.warn('Lo sentimos, debes especificar tu contraseña')
			return false
		}

		userLogin(userLoginEmail,userLoginPassword)
	}
	return (
		<>
			<Form id="login-form" className="at-form at-newRes" onSubmit={userLoginHandle}>
				<h4>Iniciar sesión</h4>
				<Form.Group controlId="formBasic-userLoginEmail">
					<Form.Label>
						Especifica aquí tu correo electrónico institucional {userLoginEmail === '' && <Badge variant="warning">Requerido</Badge>}
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="Pon aquí tu correo electrónico institucional"
						value={userLoginEmail}
						onChange={(e) => setuserLoginEmail(e.target.value)}
					/>
				</Form.Group>
				<Form.Group controlId="formBasic-userLoginPassword">
					<Form.Control
						type="password"
						placeholder="Pon aquí tu contraseña"
						value={userLoginPassword}
						onChange={(e) => setuserLoginPassword(e.target.value)}
					/>
				</Form.Group>
				<Button size="md" variant="success" type="submit" form="login-form">
					<FaSignInAlt
						style={{
							position: 'relative',
							bottom: '1px',
							marginRight: '5px',
						}}
					/>
					Iniciar sesión
				</Button>
			</Form>
		</>
	)
}
