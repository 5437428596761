import { Col, Row, Alert } from 'react-bootstrap'
import { BotonAgregar } from './BotonAgregar'
import { BotonFinalizar } from './BotonFinalizar'
import { BotonGuardar } from './BotonGuardar'
import { BotonResponder } from './BotonResponder'
import { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

export default function Logout({ user, setUser, userLogout }) {
	const history = useHistory()

	useEffect(() => {
		localStorage.removeItem('pulso.user')
			localStorage.removeItem('pulso.pulso')
			setUser(false)
			history.push('/')
	}, [user])
	return (
		<>
			<Alert variant="warning">Espera un momento... Estamos cerrando tu sesión...</Alert>
		</>
	)
}
