import React from 'react'
import { FaRegSave } from 'react-icons/fa'
import Boton from './Boton'
export function BotonResponder({ pulso }) {
	return (
		<Boton
			ruta={`/responder/${pulso._id.$oid}`}
			icono={
				<FaRegSave
					style={{
						position: 'relative',
						bottom: '1px',
					}}
				/>
			}
			variante="success"
			texto={`Responder Pulso de ${pulso.monthNatural}`}
			style={{
				marginTop: '20px',
			}}
		/>
	)
}
