import React, { useState, useRef, useEffect } from 'react'

import { useParams, useHistory } from 'react-router-dom'

import Form from 'react-bootstrap/esm/Form'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Badge from 'react-bootstrap/esm/Badge'
import Alert from 'react-bootstrap/esm/Alert'
import Button from 'react-bootstrap/esm/Button'

import cogoToast from 'cogo-toast'
import FormPulse from './FormPulse'
import { FaRegPlusSquare } from 'react-icons/fa'
import { FaAnglesRight } from 'react-icons/fa6'
import { FaAnglesLeft } from 'react-icons/fa6'
import { FaRegSave } from 'react-icons/fa'
import { FaSignOutAlt } from 'react-icons/fa'

import MapperMarker from './MapperMarker'
import Boton from './Boton'

export default function FormPulso({ user, pulso, setPulso, updatePulseInPlatform }) {
	const [step, setStep] = useState((pulso.completed && 4) || 1)
	
	const mapRef = useRef(null)
	const [width, setWidth] = useState(500)

	const [individualPulse, setIndividualPulse] = useState(pulso.individualPulse ?? { x: 0, y: 0, width: 500 })
	const [individualEnergy, setIndividualEnergy] = useState(pulso.individualEnergy ?? null)
	const [individualEnergyPct, setIndividualEnergyPct] = useState(pulso.individualEnergyPct ?? null)
	const [individualSensation, setIndividualSensation] = useState(pulso.individualSensation ?? null)
	const [individualSensationPct, setIndividualSensationPct] = useState(pulso.individualSensationPct ?? null)

	const [enterprisePulse, setEnterprisePulse] = useState(pulso.enterprisePulse ?? { x: 0, y: 0 })
	const [enterpriseEnergy, setEnterpriseEnergy] = useState(pulso.enterpriseEnergy ?? null)
	const [enterpriseEnergyPct, setEnterpriseEnergyPct] = useState(pulso.enterpriseEnergyPct ?? null)
	const [enterpriseSensation, setEnterpriseSensation] = useState(pulso.enterpriseSensation ?? null)
	const [enterpriseSensationPct, setEnterpriseSensationPct] = useState(pulso.enterpriseSensationPct ?? null)

	const [rescue, setRescue] = useState(pulso.rescue ?? '')
	const [expectations, setExpectations] = useState(pulso.expectations ?? '')

	useEffect(() => {
		let w=500;
		// if (window.innerWidth < 500) {
		// 	w=320;
		// 	setWidth(w)	
		// }
		
		// setHeight(mapRef.current.clientHeight)
	}, [window.innerWidth])

	// const [lastError, setlastError] = useState(false)

	// const history = useHistory()

	const onSubmitPulso = () => {
		// e.preventDefault()
		// setlastError(false)

		const newPulso = {
			...pulso,
			rescue: rescue,
			expectations: expectations,

			individualPulse: individualPulse,
			individualEnergy: individualEnergy,
			individualEnergyPct: individualEnergyPct,
			individualSensation: individualSensation,
			individualSensationPct: individualSensationPct,

			enterprisePulse: enterprisePulse,
			enterpriseEnergy: enterpriseEnergy,
			enterpriseEnergyPct: enterpriseEnergyPct,
			enterpriseSensation: enterpriseSensation,
			enterpriseSensationPct: enterpriseSensationPct,

			completed: true,
		}

		// setPulso(newPulso)
		updatePulseInPlatform(newPulso)

		cogoToast.success('El pulso de ' + pulso.monthNatural + ' ha sido capturado correctamente')
		advance(5)
	}

	// pulse
	const transformClickIndividual = function (e) {
		// console.log(e);
		let posX = e.nativeEvent.layerX
		let posY = e.nativeEvent.layerY

		// console.log(posX, posY);

		setIndividualPulse({ x: posX, y: posY, width: width })

		var rect = e.target.getBoundingClientRect()
		var x = e.clientX - rect.left //x position within the element.
		var y = e.clientY - rect.top //y position within the element.
		// console.log("Left? : " + x + " ; Top? : " + y + ".");

		const energy = Math.floor(width - y)
		const sensation = Math.floor(x)

		// console.log('Energy: '+energy+' Sensation:'+sensation);

		setIndividualEnergy(energy)
		setIndividualSensation(sensation)

		//now in % relative to 100%=width

		const energyPct = Math.floor((energy / width) * 100)
		const sensationPct = Math.floor((sensation / width) * 100)

		console.log('Energy: ' + energyPct + ' Sensation:' + sensationPct)

		setIndividualEnergyPct(energyPct)
		setIndividualSensationPct(sensationPct)
	}

	const transformClickEnterprise = function (e) {
		// console.log(e);
		let posX = e.nativeEvent.layerX
		let posY = e.nativeEvent.layerY

		// console.log(posX, posY);

		setEnterprisePulse({ x: posX, y: posY, width: width })

		var rect = e.target.getBoundingClientRect()
		var x = e.clientX - rect.left //x position within the element.
		var y = e.clientY - rect.top //y position within the element.
		// console.log("Left? : " + x + " ; Top? : " + y + ".");

		const energy = Math.floor(width - y)
		const sensation = Math.floor(x)

		// console.log('Energy: '+energy+' Sensation:'+sensation);

		setEnterpriseEnergy(energy)
		setEnterpriseSensation(sensation)

		//now in % relative to 100%=width

		const energyPct = Math.floor((energy / width) * 100)
		const sensationPct = Math.floor((sensation / width) * 100)

		console.log('Energy: ' + energyPct + ' Sensation:' + sensationPct)

		setEnterpriseEnergyPct(energyPct)
		setEnterpriseSensationPct(sensationPct)
	}
	// pulse

	const advance = (stepToAdvance) => {
		// console.log(stepToAdvance)
		setStep(stepToAdvance)
	}

	const isValidToAdvance = (step) => {
		if (step === 1) {
			return rescue !== ''
		}
		if (step === 2) {
			return individualPulse.x !== 0 && individualPulse.y !== 0
		}
		if (step === 3) {
			return enterprisePulse.x !== 0 && enterprisePulse.y !== 0
		}
		if (step === 4) {
			return expectations !== ''
		}
		return false
	}

	return (
		<>
			<Form id="new-form" className="at-form pt-3" onSubmit={onSubmitPulso}>
				{step < 5 && (
					<>
						<div className="px-3 text-left">
							<h5>Estimad@ {user.userName}:</h5>
							<p>
								Como parte de la evolución consciente que promovemos en La Vaca Independiente, buscamos incentivar un entorno
								organizacional favorable en el que se fomenten los sentidos de permanencia, trascendencia, transformación y
								crecimiento personal y profesional. Realizamos este esfuerzo como una manera de procurarte y cuidarte.
							</p>
							<p>
								Por ello, te compartimos un pequeño cuestionario que se responderá la última semana de cada mes. Este ejercicio nos
								permitirá conocer y analizar tu bienestar y desarrollo laborales.
							</p>
						</div>
						<div className="text-center">
							<h4>Paso {step}/4</h4>
						</div>
					</>
				)}

				{step === 1 && (
					<Row>
						<Col md={{ size: 12, offset: 0 }} className="text-center">
							<Form.Group controlId="formBasicNewAtencionTextarea">
								<Form.Label>
									<h5>
										{user.userName}, ¿Qué rescatas del mes de {pulso.monthNatural}?
									</h5>
								</Form.Label>
								<Form.Control
									as="textarea"
									rows={6}
									placeholder="Introduce aquí tu respuesta"
									value={rescue}
									onChange={(e) => setRescue(e.target.value)}
									style={{ minHeight: '193px', width: '100%' }}
								/>
							</Form.Group>
							<div className="text-center">
								<Button
									className="mt-3"
									size="md"
									variant={isValidToAdvance(step) ? 'success' : 'warning'}
									onClick={() => {
										if (isValidToAdvance(step)) {
											advance(2)
										} else {
											cogoToast.warn('Por favor contesta la pregunta...')
										}
									}}
								>
									{isValidToAdvance(step) ? (
										<>
											Continuar al Paso 2 <FaAnglesRight />
										</>
									) : (
										'Por favor contesta la pregunta...'
									)}
								</Button>
							</div>
						</Col>
					</Row>
				)}
				{step === 2 && (
					<Row>
						<Col md={{ span: 12, offset: 0 }} className="text-center">
							<h4 className="pb-0">¿Cómo te sientes en tu desarrollo y crecimiento personal?</h4>
							<p className="pb-2">
								<small>Da click sobre cualquier area dentro del termómetro que quieras indicar.</small>
							</p>
						</Col>
						<Col ref={mapRef} md={{ span: 12, offset: 0 }} className="text-center" style={{ position: 'relative' }}>
							{individualPulse.x > 0 && <MapperMarker posX={individualPulse.x} posY={individualPulse.y}></MapperMarker>}
							<img
								src="../cdn/img/termometro_big.jpg"
								alt=""
								style={{ width: `${width}px`, height: 'auto', cursor: 'pointer' }}
								onClick={transformClickIndividual}
							/>
							<div className="text-center">
								<Button
									className="mt-3 mr-3"
									size="md"
									variant={'info'}
									onClick={() => {
										advance(1)
									}}
								>
									<>
										<FaAnglesLeft /> Regresar al paso 1
									</>
								</Button>

								<Button
									className="mt-3"
									size="md"
									variant={isValidToAdvance(step) ? 'success' : 'warning'}
									onClick={() => {
										if (isValidToAdvance(step)) {
											advance(3)
										} else {
											cogoToast.warn('Por favor coloca tu indicador')
										}
									}}
								>
									{isValidToAdvance(step) ? (
										<>
											Continuar al Paso 3 <FaAnglesRight />
										</>
									) : (
										'Por favor coloca tu indicador...'
									)}
								</Button>
							</div>
						</Col>
					</Row>
				)}
				{step === 3 && (
					<Row>
						<Col md={{ span: 12, offset: 0 }} className="text-center">
							<h4 className="pb-0">¿Cómo te sientes en tu desarrollo profesional, tareas, actividades y proyectos?</h4>
							<p className="pb-2">
								<small>Da click sobre cualquier area dentro del termómetro que quieras indicar.</small>
							</p>
						</Col>
						<Col md={{ span: 12, offset: 0 }} className="text-center" style={{ position: 'relative' }}>
							{enterprisePulse.x > 0 && <MapperMarker posX={enterprisePulse.x} posY={enterprisePulse.y}></MapperMarker>}
							<img
								src="../cdn/img/termometro_big.jpg"
								alt=""
								style={{ width: `${width}px`, height: 'auto', cursor: 'pointer' }}
								onClick={transformClickEnterprise}
							/>
							<div className="text-center">
								<Button
									className="mt-3 mr-3"
									size="md"
									variant={'info'}
									onClick={() => {
										advance(2)
									}}
								>
									<>
										<FaAnglesLeft /> Regresar al paso 2
									</>
								</Button>

								<Button
									className="mt-3"
									size="md"
									variant={isValidToAdvance(step) ? 'success' : 'warning'}
									onClick={() => {
										if (isValidToAdvance(step)) {
											advance(4)
										} else {
											cogoToast.warn('Por favor coloca tu indicador')
										}
									}}
								>
									{isValidToAdvance(step) ? (
										<>
											Continuar al Paso 4 <FaAnglesRight />
										</>
									) : (
										'Por favor coloca tu indicador...'
									)}
								</Button>
							</div>
						</Col>
					</Row>
				)}
				{step === 4 && (
					<Row>
						<Col md={{ size: 12, offset: 0 }} className="text-center">
							<Form.Group controlId="formBasicNewAtencionTextarea">
								<Form.Label>
									<h5>El siguiente mes espero lograr...</h5>
								</Form.Label>
								<Form.Control
									as="textarea"
									rows={6}
									placeholder="Introduce aquí tu respuesta"
									value={expectations}
									onChange={(e) => setExpectations(e.target.value)}
									style={{ minHeight: '193px', width: '100%' }}
								/>
							</Form.Group>
							<div className="text-center">
								<Button
									className="mt-3 mr-3"
									size="md"
									variant={'info'}
									onClick={() => {
										advance(2)
									}}
								>
									<>
										<FaAnglesLeft /> Regresar al paso 2
									</>
								</Button>
								<Button
									className="mt-3"
									size="md"
									variant={isValidToAdvance(step) ? 'success' : 'warning'}
									onClick={() => {
										if (isValidToAdvance(step)) {
											onSubmitPulso()
										} else {
											cogoToast.warn('Por favor contesta la pregunta...')
										}
									}}
								>
									{isValidToAdvance(step) ? (
										<>
											<FaRegSave /> Finalizar
										</>
									) : (
										'Por favor contesta la pregunta...'
									)}
								</Button>
							</div>
						</Col>
					</Row>
				)}
				{step === 5 && (
					<Row>
						<Col md={{ size: 12, offset: 0 }}>
							<h5>{user.userName}:</h5>
							<p>
								Agradecemos el tiempo que le has dedicado a este cuestionario. Para nosotros, es de suma importancia conocer mejor tu
								bienestar laboral.
							</p>
							<p>
								Tus respuestas son totalmente confidenciales y serán tomadas en cuenta. Este cuestionario nos permitirá saber tus
								necesidades laborales y personales para retroalimentarte de manera pertinente a ti y a cada uno de nuestros
								colaboradores. Con este ejercicio, trazaremos un plan de acompañamiento para construir un desarrollo profesional y
								personal que resulte adecuado individual y organizacionalmente.{' '}
							</p>
							<p>Esperamos que este mes que inicia cumplas tus propósitos planteados.</p>
							<p>Un abrazo fraterno.</p>

							<div className="text-center">
								<p>Ya puedes cerrar sesión</p>
								<Boton
									ruta="/logout"
									variante="danger"
									icono={<FaSignOutAlt style={{ position: 'relative', bottom: '1px' }} />}
									texto={`Cerrar la sesión de ${user.userName}`}
									onClick={(e) => {
										// console.log('userLogout desde Tutorial')
										// userLogout(e)
									}}
								/>
							</div>
						</Col>
					</Row>
				)}
			</Form>

			{/* <Row>
				<Col md={12} style={{ marginTop: '15px' }} className="text-center">
					<Button size="md" variant={isValidToSubmitForm ? 'success' : 'warning'} type="submit" form="new-form">
						<FaRegPlusSquare style={{ position: 'relative', bottom: '1px', marginRight: '5px' }} />
						{'Registrar Pulso'}
					</Button>
				</Col>
			</Row> */}
		</>
	)
}
