import React from 'react'
import Button from 'react-bootstrap/esm/Button'
import { Link } from 'react-router-dom'

export default function Boton({ ruta, variante, icono, texto, style, onClick, size }) {
	size = size || 'md'
	return (
		<Link to={ruta}>
			<Button size={size} style={style} variant={variante} onClick={onClick}>
				{icono} {texto}
			</Button>
		</Link>
	)
}
