import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Alert from 'react-bootstrap/esm/Alert'
import { IoWarningOutline } from 'react-icons/io5'
import Boton from './Boton'
import { FaSignOutAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default function TopHeader({ title, user, userLogout }) {
	return (
		<Row className="at-header">
			<Col className="at-header-left-logo text-left" md={6}>
				<Link to="/">
					<img src="https://pi2.lavaca.edu.mx/assets/img/logos/lvi_negro@2x.png" alt="" />
				</Link>
			</Col>
			<Col md={6} className="at-user">
				{user ? (
					<>
						<p>
							Hola <span>{user.userName}</span> ({user.userEmail})
						</p>

						<Boton
							size="sm"
							ruta="/logout"
							variante="danger"
							icono={<FaSignOutAlt style={{ position: 'relative', bottom: '1px' }} />}
							texto={`Cerrar sesión`}
							// onClick={(e) => {
							// 	// userLogout(e)
							// }}
							style={{ marginTop: '3px' }}
						/>
					</>
				) : (
					<Alert variant="warning" className="at-guest text-center">
						<IoWarningOutline className="at-warning" /> Por favor completa tus datos básicos para iniciar
					</Alert>
				)}
			</Col>
		</Row>
	)
}
