import { FormLogin } from './FormLogin'
import Row from 'react-bootstrap/esm/Row'
import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import { FaSignOutAlt } from 'react-icons/fa'
import Boton from './Boton.jsx'
import { Link } from 'react-router-dom'
import BelowListOptions from './BelowListOptions'

export default function Bienvenida({ platformURL, user, userLogin, userLogout, pulso, setLoadingBar, setAlert }) {
	return (
		<Row>
			<Col md={{ span: 8, offset: 2 }} className="va-right va-tutorial">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/">Inicio</Link>
						</li>
					</ol>
				</nav>
				{!user ? (
					<>
						<div className="px-3 pb-4">
							<h5>Estimad@ colaborador:</h5>
							<p>
								Como parte de la evolución consciente que promovemos en La Vaca Independiente, buscamos incentivar un entorno
								organizacional favorable en el que se fomenten los sentidos de permanencia, trascendencia, transformación y
								crecimiento personal y profesional. Realizamos este esfuerzo como una manera de procurarte y cuidarte.
							</p>
							<p>
								Por ello, te compartimos un pequeño cuestionario que se responderá la última semana de cada mes. Este ejercicio nos
								permitirá conocer y analizar tu bienestar y desarrollo laboral.
							</p>
							<p>
								Te recordamos que la fecha límite para contestar el pulso es el día 30 del presente mes
							</p>
							<p>Para comenzar, por favor inicia sesión con tu usuario y contraseña de Plataforma Interna LVI</p>
						</div>
					</>
				) : (
					<>
						<div className="px-3">
							<h5>Estimad@ {user.userName}:</h5>
							<p>
								Como parte de la evolución consciente que promovemos en La Vaca Independiente, buscamos incentivar un entorno
								organizacional favorable en el que se fomenten los sentidos de permanencia, trascendencia, transformación y
								crecimiento personal y profesional. Realizamos este esfuerzo como una manera de procurarte y cuidarte.
							</p>
							<p>
								Por ello, te compartimos un pequeño cuestionario que se responderá la última semana de cada mes. Este ejercicio nos
								permitirá conocer y analizar tu bienestar y desarrollo laboral.
							</p>
							<p>
								Te recordamos que la fecha límite para contestar el pulso es el día 30 del presente mes
							</p>

							<div className="text-center">
								<BelowListOptions user={user} pulso={pulso} />
								<hr />
								<Boton
									ruta="/logout"
									variante="danger"
									icono={<FaSignOutAlt style={{ position: 'relative', bottom: '1px' }} />}
									texto={`Cerrar la sesión de ${user.userName}`}
									onClick={(e) => {
										// console.log('userLogout desde Tutorial')
										// userLogout(e)
									}}
								/>
							</div>
						</div>
					</>
				)}
				<Row>
					<Col md={{ span: 6, offset: 3 }} className="va-content text-center pt-4">
						{!user ? (
							<>
								<FormLogin platformURL={platformURL} userLogin={userLogin} setAlert={setAlert} setLoadingBar={setLoadingBar} />
							</>
						) : (
							<></>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
