import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'

export default function MapperMarker({ posY, posX }) {
	let newPosX = posX - 25 + 'px'
	let newPosY = posY - 25 + 'px'
	return (
		<div className="maper-marker" style={{ left: newPosX, top: newPosY }}>
			<FaCheckCircle></FaCheckCircle>
		</div>
	)
}
