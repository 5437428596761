import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/App.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
// import './assets/css/Fresca.css'
// import './assets/css/flatly.css'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import SweetAlert from 'react-bootstrap-sweetalert'
import TopBarProgress from 'react-topbar-progress-indicator'

import TopHeader from './components/TopHeader'
import Bienvenida from './components/Bienvenida'
import VistaPulso from './components/VistaPulso'
import Logout from './components/Logout'

function App() {
	const history = useHistory()

	const loadLastPulseFromPlatform = (userId) => {
		setLoadingBar(true)
		axios
			.get(`${platformURL}/last/${userId}`)
			.then((res) => {
				setLoadingBar(false)
				setPulso(res.data.pulse)
				// handleLogin(res.data)
			})
			.catch((err) => {
				console.log(err)
				// if (err.response.data) {
				// 	// console.log(err.response)
				// 	setLoadingBar(false)
				// 	cogoToast.error(err.response.data.message)
				// }
			})
	}

	const loadPulseFromPlatform = (id) => {
		setLoadingBar(true)
		axios
			.get(`${platformURL}/${id}`)
			.then((res) => {
				setLoadingBar(false)
				handleLogin(res.data)
			})
			.catch((err) => {
				if (err.response.data) {
					// console.log(err.response)
					setLoadingBar(false)
					cogoToast.error(err.response.data.message)
				}
			})
	}

	const updatePulseInPlatform = (pulse) => {
		console.log(pulse)
		// return
		setLoadingBar(true)
		axios
			.post(`${platformURL}/${pulse._id.$oid}`, pulse)
			.then((res) => {
				setLoadingBar(false)
				console.log(res)
				loadLastPulseFromPlatform(pulse.userId.$oid)
				// handleLogin(res.data)
			})
			.catch((err) => {
				console.log(err)
				// if (err.response.data) {
				// 	// console.log(err.response)
				// 	setLoadingBar(false)
				// 	cogoToast.error(err.response.data.message)
				// }
			})
	}

	// Notifications
	// Loader
	const [loadingBar, setLoadingBar] = useState(false)
	TopBarProgress.config({
		barColors: {
			0: '#1e7e34',
			'1.0': '#1e7e34',
		},
		barThickness: 5,
		shadowBlur: 5,
	})
	// Loader
	// SweetAlert
	const [alert, setAlert] = useState(false)
	const alertHide = () => {
		setAlert(false)
	}
	// SweetAlert
	// Notifications
	// const checkPulsoForReload = () => {
	// 	const saved = JSON.parse(localStorage.getItem('pulso.pulso'))

	// 	if (saved && saved[0]?.pulse) {
	// 		return saved
	// 	} else {
	// 		return null
	// 	}
	// }

	const [pulso, setPulso] = useState(null)

	// **** Environment
	const platformURL = process.env.REACT_APP_PI2_LOCATION || false
	// **** Environment

	// **** Usuario

	const checkIfUserForReload = () => {
		if (JSON.parse(localStorage.getItem('pulso.user'))) {
			if (JSON.parse(localStorage.getItem('pulso.user')).userId) {
				loadLastPulseFromPlatform(JSON.parse(localStorage.getItem('pulso.user')).userId)
			}
			return JSON.parse(localStorage.getItem('pulso.user'))
		}

		return false
	}
	const [user, setUser] = useState(checkIfUserForReload)

	// useEffect(() => {
	// 	console.log('cambio user!')
	// 	console.log(user)
	// }, [user])

	const handleLogin = (userData) => {
		console.log('handleLogin');
		const newUser = { userName: userData.user, userEmail: userData.email, userId: userData.id }

		console.log(newUser);
		setUser(newUser)

		console.log('handleLogin 2');

		localStorage.setItem('pulso.user', JSON.stringify(newUser))
		cogoToast.success(`Bienvenido ${userData.user}!`)

		console.log('nuevo');		
		if (userData.pulse) {
			// localStorage.setItem('pulso.pulso', JSON.stringify(userData.pulse))
			setPulso(userData.pulse)
		} else {
			console.log('no hay pulse');
		}

		// history.push(`/tutorial`)
	}
	// **** Usuario

	const performLoginCheck = (email, password) => {
		setLoadingBar(true)
		axios
			.post(`${platformURL}/start`, { email, password })
			.then((res) => {
				setLoadingBar(false)
				// console.log(res.data)
				handleLogin(res.data)
			})
			.catch((err) => {
				if (err.response?.data) {
					// console.log(err.response)
					setLoadingBar(false)
					cogoToast.error(err.response.data.message)
				}
			})
	}

	const userLogin = (userLoginEmail, userLoginPassword) => {
		performLoginCheck(userLoginEmail, userLoginPassword)
	}
	const userLogout = (e = null) => {
		if (e) {
			e.preventDefault()
		}
		localStorage.removeItem('pulso.user')
		localStorage.removeItem('pulso.pulso')
		setUser(false)
	}
	// Usuario

	const protectedRoutes = ['/responder/:id']

	return (
		<Router>
			<div>{loadingBar && <TopBarProgress />}</div>
			<Container fluid="fluid" className="pt-3">
				<Row className="at-container shadow-lg bg-white rounded">
					<Col className="at-mainCol" md={12}>
						<TopHeader title="" user={user} userLogout={userLogout} />

						<Route path={['/bienvenida', '/']} exact>
							<Bienvenida
								platformURL={platformURL}
								user={user}
								userLogin={userLogin}
								userLogout={userLogout}
								pulso={pulso}
								setAlert={setAlert}
								setLoadingBar={setLoadingBar}
							/>
						</Route>
						<Route path={protectedRoutes}>
							<VistaPulso
								user={user}
								userLogout={userLogout}
								pulso={pulso}
								setPulso={setPulso}
								loadPulseFromPlatform={loadPulseFromPlatform}
								updatePulseInPlatform={updatePulseInPlatform}
							/>
						</Route>
						<Route path={'/logout'}>
							<Logout user={user} setUser={setUser} userLogout={userLogout} />
						</Route>
					</Col>
				</Row>
			</Container>
			{alert && (
				<SweetAlert type={alert.type} title={alert.title} onConfirm={alertHide}>
					{alert.message}
				</SweetAlert>
			)}
		</Router>
	)
}

export default App
